
import type { PropType } from 'vue';
import { defineComponent, reactive, toRefs } from 'vue';


export default defineComponent({
  props: {
    items: {
      type: Array as PropType<{ href: string, title: string }[]>,
      default: () => {
        return []
      }
    },
    title: {
      type: String,
      default: 'vue'
    }
  },
  setup() {
    const state = reactive({
      isActive: false
    })
    const clickTitle = () => {
      state.isActive = !state.isActive
    }
    return {
      ...toRefs(state), clickTitle
    }
  }
})
